/* General container styling */
.create-module-container {
  max-width: 900px;
  margin: 40px auto;  /* Center the container */
  padding: 30px;
  background-color: #ffffff;  /* White background for clarity */
  border-radius: 8px;  /* Rounded corners for modern design */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
}

/* Header styling */
.create-header {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

/* Section for forms */
.form-section {
  background-color: #f4f7fa;  /* Light gray for the form background */
  padding: 20px;
  border-radius: 8px;  /* Rounded corners */
  margin-bottom: 30px;  /* Spacing between sections */
  border: 1px solid #ddd;  /* Border for definition */
}

/* Form group - spacing between label and input */
.form-group {
  margin-bottom: 20px;
}

/* Form labels */
.form-group label {
  font-weight: bold;
  color: #333;
  display: block;  /* Ensure label is on a separate line */
  margin-bottom: 8px;  /* Space between label and input */
}

/* Input fields */
.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease-in-out;
}

/* Focused input */
.form-group input:focus,
.form-group select:focus {
  border-color: #4e73df;
  outline: none;
}

/* Add equation/variable button */
.btn-add {
  display: inline-block;
  background-color: #4e73df;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease-in-out;
}

.btn-add:hover {
  background-color: #3659b5;
}

/* Submit button styling */
.btn-submit {
  background-color: #28a745;
  color: #fff;
  padding: 12px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
  margin-top: 20px;
}

.btn-submit:hover {
  background-color: #218838;
}

/* Response message */
.response-message {
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
}

.response-message.success {
  background-color: #d4edda;
  color: #155724;
  border-left: 5px solid #28a745;
}

.response-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border-left: 5px solid #dc3545;
}

/* Equations and variables list */
.equations-section,
.variables-section {
  margin-bottom: 30px;
}

/* Styling individual rows for equations and variables */
.equation-row,
.variable-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.equation-row input,
.variable-row input,
.variable-row select {
  flex: 1;
  margin-right: 10px;  /* Spacing between inputs */
}

/* Align the "Remove" button for variables */
.variable-row button,
.equation-row button {
  background-color: #dc3545;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.variable-row button:hover,
.equation-row button:hover {
  background-color: #c82333;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .equation-row,
  .variable-row {
    flex-direction: column;  /* Stack inputs vertically on small screens */
  }

  .equation-row input,
  .variable-row input,
  .variable-row select {
    margin-right: 0;
    margin-bottom: 10px;  /* Add spacing between stacked inputs */
  }
}

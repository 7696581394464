body {
    background-color: #f4f7fa;
    color: #333;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .container {
    margin-top: 50px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;  /* Align items on opposite sides */
    align-items: center;  /* Vertically center the items */
    padding: 10px 20px;  /* Add padding for spacing */
  }
  
  h1 {
    color: #4e73df;
    font-weight: bold;
    font-size: 2.2em;
    margin: 0;  /* Remove default margin */
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  input, select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
  
  input:focus, select:focus {
    border-color: #4e73df;
    outline: none;
  }
  
  .btn-primary {
    background-color: #4e73df;
    border-color: #4e73df;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .btn-primary:hover {
    background-color: #2e59d9;
    border-color: #2653d4;
    transform: scale(1.05);
  }
  
  #results {
    margin-top: 30px;
    padding: 20px;
    background-color: #e2eafc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
  
  #results p, #results table {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  #plot img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    height: 60px;
  }
  
  .logo-container {
    position: relative;
    top: 0;
    right: 0;
    padding: 10px;
  }
  
  .logo-text {
    font-size: 16px;
    color: #4e73df;
    text-align: right;
  }
  
  .footer {
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: #666;
  }
  
  .footer a {
    color: #4e73df;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .context-menu {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .context-menu button {
    background-color: #4e73df;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }
  
  .context-menu button:hover {
    background-color: #2e59d9;
  }
  
  .context-menu button:focus {
    outline: none;
  }
  
  .log-section {
    background-color: #e2eafc;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    max-height: 200px;
    overflow-y: auto;
  }
  
  .log-section h3 {
    color: #4e73df;
    margin-bottom: 10px;
  }
  
  .log-section ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
  .log-section li {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    color: #333;
  }
  
  .log-section li:last-child {
    border-bottom: none;
  }
  
  .logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #ff6347;
    color: white;
    padding: 10px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #ff4500;
  }
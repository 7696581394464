/* B1.css */

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.logo {
  width: 100px;
  height: auto;
}

.logo-text {
  margin-left: 10px;
  font-size: 14px;
}

.form-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.btn {
  width: 100%;
}

.result {
  background-color: #d4edda;
  padding: 15px;
  border-radius: 5px;
}

.error {
  background-color: #f8d7da;
  padding: 15px;
  border-radius: 5px;
}

.log-section {
  background-color: #e2e3e5;
  padding: 15px;
  border-radius: 5px;
}

.log-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Überschrift im Beschreibungsteil */
.description h2 {
  color: #333;
  font-size: 2.5rem; /* Vergrößerte Schriftgröße */
  margin-bottom: 20px;
}

/* Absätze im Beschreibungsteil */
.description p {
  color: #555;
  font-size: 1.5rem; /* Vergrößerte Schriftgröße */
  line-height: 1.8; /* Bessere Lesbarkeit durch erhöhten Zeilenabstand */
}

/* Buttons in der Aktionssektion */
.action {
  text-align: center;
}

.action .btn-primary {
  padding: 15px 30px; /* Größerer Button */
  font-size: 18px; /* Größere Schrift für den Button */
  background-color: #007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.action .btn-primary:hover {
  background-color: #0056b3;
}

/* Log-Section */
.log-section {
  margin-top: 20px;
}

.log-section ul {
  list-style-type: none;
  padding: 0;
}

.log-section ul li {
  color: #444;
  font-size: 1.1rem; /* Leicht größere Schrift für Logs */
  background: #f9f9f9;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
}

/* B1Module.css */

/* Layout für das Hauptmodul mit Sidebar */
.b1module-container {
    display: flex;
  }
  
  /* Der Hauptinhalt wird neben der Sidebar angezeigt */
  .content {
    margin-left: 250px; /* Breite der Sidebar */
    padding: 20px;
    width: 100%;
  }
  
  /* Sidebar Grundlayout */
  .sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #f4f7fa;
    padding-top: 20px;
    padding-left: 10px;
  }
  
  /* Stil für die Links im Sidebar-Menü */
  .sidebar .nav-link {
    color: #4e73df;
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  
  /* Stil für den Header der Sidebar */
  .sidebar-header {
    font-size: 1.5em;
    font-weight: bold;
    color: #4e73df;
    margin-bottom: 20px;
  }
  
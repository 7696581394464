/* Ensure table styling with light grey background for the header */
.result-table thead th {
  background-color: #dfdcdc !important; /* Light grey */
  color: #000; /* Optional: ensure text color is readable */
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}

/* Picture row background - Light blue */
.result-table .picture-row td {
  background-color: #e6f7ff !important; /* Light blue */
  font-weight: bold;
}

/* Column hover for modules */
.module-column {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.module-column:hover {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

/* Image Preview Styling */
.image-preview-container img {
  width: 100px;
  height: auto;
}

.image-preview-container {
  margin: 10px;
  display: inline-block;
  text-align: center;
}

/* Center Button Styling */
.center-button button {
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-section {
  flex: 1;
  text-align: left;
}

.center-section {
  flex: 1;
  text-align: center;
}

.logo-container {
  flex: 1;
  text-align: right;
}
.fixed-size-image {
  width: 150px;       /* Set the width you want */
  height: 150px;      /* Set the height you want */
  object-fit: contain;  /* Ensures the image scales without distortion */
  border: 1px solid #ccc;  /* Optional: add a border to the image */
  padding: 5px;        /* Optional: add padding around the image */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  /* Optional: add a slight shadow */
}

.fixed-size-icon {
  width: 150px;  /* Set the icon width */
  height: 150px; /* Set the icon height */
  object-fit: contain;  /* Ensures the icon scales without distortion */
}

.file-preview-container {
  margin: 10px;   /* Add spacing between preview items */
  text-align: center;
}
/* Add styling for the file input and text area */
input[type="file"] {
  margin-bottom: 10px;
  margin-top: 20px;
  border: 2px solid #adabab; /* Darker border */
  border-radius: 4px; /* Optional: rounded corners */
}
/* Add hover effect for file input */
input[type="file"]::file-selector-button {
  background-color: white; /* Default background */
  border: 2px solid #adabab; /* Darker border */
  border-right: 4px solid #adabab; /* Explicitly setting right border */
  padding: 5px 10px;
  cursor: pointer;
}
textarea.form-control {
  margin-top: 20px; /* Extra margin for the description box */
  margin-bottom: 10px;
  border: 2px solid #adabab; /* Darker border */
  border-radius: 4px; /* Optional: rounded corners */
}

/* Button styling */
.explore-button {
  background-color: #28a745; /* Bright green */
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.explore-button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Arrow styling */
.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #28a745; /* Green arrow pointing down */
  margin: 0 auto;
  margin-top: 10px; /* Space between the text and arrow */
}

.speak-button {
    background-color: #4CAF50; /* Green background */
    color: white;              /* White text */
    border: none;              /* Remove borders */
    padding: 10px 40px;        /* Add some padding */
    text-align: center;        /* Centered text */
    text-decoration: none;     /* Remove underline */
    display: inline-block;     /* Make the button inline */
    font-size: 16px;           /* Set font size */
    margin: 4px 2px;           /* Some margin */
    cursor: pointer;           /* Pointer/hand icon */
    border-radius: 5px;        /* Rounded corners */
}

.speak-button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.module-icon {
  width: 40px;   /* Increase width as needed */
  height: 40px;  /* Increase height as needed */
  object-fit: contain;  /* Ensures the icon fits without distortion */
  margin-right: 8px;    /* Adds space between the icon and text */
  vertical-align: middle;
}

/* Mobile Responsive Design */
@media only screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .form-label {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  .fixed-size-image,
  .fixed-size-icon {
    width: 100px;
    height: 100px;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .center-section,
  .logo-container {
    flex: 1;
    text-align: center;
  }

  .result-table {
    font-size: 12px;
  }

  .module-column {
    padding: 8px;
  }
}
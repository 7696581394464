/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f4f7fa;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo-container {
  margin-right: 20px;
}

.logo {
  height: 50px;
}

.company-name {
  font-size: 2.0em;
  margin: 0;
  color: #333; /* Darker color for better contrast */
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.unique-id-container {
  font-size: 1.0em;
  background-color: #f4f7fa; /* Slightly darker background for better readability */
  color: #333; /* Darker text color for contrast */
  padding: 10px;
  border-radius: 8px;
}

button {
  padding: 10px 20px;
  background-color: #5276e0;
  color: white;
  border: none;
  border-radius: 10px !important;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #365bbf;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Category and Module Styles */
.category-menu, .module-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

/* Common style for both category and module items */
.category-item, .module-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-color: #f4f7fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-decoration: none;
  color: #333; /* Darker text color for better contrast */
  cursor: pointer;
}

.category-item:hover, .module-tile:hover {
  transform: scale(1.05);
  background-color: #e4e8f5;
}

/* Icon and text alignment */
.category-icon, .module-icon {
  font-size: 2.5em;
  margin-bottom: 10px;
  text-align: center;
  color: #4e73df;
}

.category-name, .module-name {
  font-size: 1.2em;
  text-align: center;
  color: #333; /* Darker text for better contrast */
  text-decoration: none;
}

/* Module grid container */
.module-grid {
  margin-top: 30px;
}

/* Add space above module headings */
.module-container h3 {
  margin-top: 80px;
  font-size: 1.5em;
  color: #666;
}

/* Style for module tile links to ensure they take up full space */
.module-tile a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
}

/* Media Query for Mobile (adjust breakpoint as needed) */
@media (max-width: 768px) { 
  .header {
    flex-direction: column; /* Stack header elements vertically */
    padding: 5px; /* Reduce padding on mobile */
  }

  .header-left {
    justify-content: center; /* Center logo and company name */
    margin-bottom: 10px; /* Add space below logo on mobile */
  }

  .logo-container {
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 10px; /* Add bottom margin for spacing */
  }

  .logo {
    height: 30px; /* Reduce logo height on mobile */
  }

  .company-name {
    font-size: 1.5em; /* Reduce company name font size */
    text-align: center; /* Center company name */
  }

  .header-right {
    justify-content: center; /* Center right-aligned elements */
  }
}
/* B1Menu.css */

/* Erstellt 200px Abstand vom linken Rand bis zum Menüstart */
.custom-nav {
    margin-left: 50px;
  }
  
  /* Positioniert den Navbar.Brand am rechten Rand */
  .navbar-brand-right {
    margin-left: auto;
    padding-right: 50px;
  }
  
  /* Sorgt dafür, dass die gesamte Navbar gestreckt wird und der Inhalt richtig positioniert ist */
  .custom-navbar {
    width: 100%;
  }
  
body {
  background-color: #f4f7fa;
  color: #333;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Container für die gesamte Seite */
.calculation-assist-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(to right, #4e73df, #2e59d9);
  color: #fff;
  padding: 80px 20px;
  text-align: center;
}

.hero-section h1 {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-section .hero-subtitle {
  font-size: 1.2em;
  max-width: 600px;
  margin: 0 auto;
}

/* Features Section */
.features-section {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.features-section h2 {
  font-size: 2.2em;
  color: #4e73df;
  margin-bottom: 40px;
  font-weight: bold;
}

.features-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-card {
  background-color: #f4f7fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.feature-card h3 {
  color: #4e73df;
  margin-bottom: 15px;
  font-size: 1.4em;
  font-weight: bold;
}

.feature-card p {
  font-size: 1em;
  color: #555;
  line-height: 1.5em;
}

/* Call to Action Section */
.cta-section {
  background-color: #e2eafc;
  text-align: center;
  padding: 60px 20px;
  margin-top: auto; /* Schiebt den CTA-Block nach unten wenn nötig */
}

.cta-section h2 {
  color: #4e73df;
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold;
}

.cta-section p {
  font-size: 1.1em;
  max-width: 600px;
  margin: 0 auto 30px auto;
  color: #555;
  line-height: 1.5em;
}

.cta-section .btn {
  background-color: #4e73df;
  border: none;
  color: #fff;
  padding: 15px 30px;
  font-size: 1.1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-section .btn:hover {
  background-color: #2e59d9;
  transform: scale(1.05);
}

/* Header Anpassungen (falls vorhanden) */
.header {
  display: flex;
  justify-content: space-between;  
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.header h1 {
  color: #4e73df;
  font-weight: bold;
  font-size: 1.8em;
  margin: 0;
}

/* Falls Logos oder weitere Elemente im Header */
.logo {
  height: 40px;
}

/* Responsiveness */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2em;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 1.8em;
  }
  
  .hero-section .hero-subtitle {
    font-size: 1em;
  }

  .feature-card h3 {
    font-size: 1.2em;
  }
}

/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f7fa;
    padding: 20px;
    border-bottom: 2px solid #4e73df;
  }
  
  h1 {
    color: #4e73df;
    font-weight: bold;
    font-size: 2.2em;
    margin: 0;
    margin-bottom: 50px; /* Add more space below the h1 */
  }
  
  h2 {
    color: #666;
    font-size: 1.5em;
    margin-top: 50px; /* Add more space above the h2 */
  }
  

  /* Category menu styling
  .category-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
  }
  
  .category-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: center;
    padding: 20px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .category-item:hover {
    transform: scale(1.05);
  }
  
  .category-icon {
    font-size: 2.5em;
    color: #4e73df;
  }
  
  .category-name {
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
  
  /* Module list styling
  .module-list {
    list-style: none;
    padding: 0;
    margin-top: 15px;
  }
  
  .module-list li {
    margin: 10px 0;
    font-size: 1em;
  }
  
  .module-list li a {
    color: #4e73df;
    text-decoration: none;
  }
  
  .module-list li a:hover {
    text-decoration: underline;
  } */
  
  /* Logo styling */
  .logo {
    height: 60px;
  }
  
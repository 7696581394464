.container {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .btn-primary {
    margin-top: 10px;
  }
  
  #results {
    margin-top: 20px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }
  